<template>
	<div class="news">
		<div class="news-top center-noflex font-first" :style='{"background-image": "url("+backgrond_img+")"}'>
			<div style="margin-bottom: 5px; font-size: 48px; font-weight: 900">
				{{cate_title}}
			</div>
			<div>{{cate_content}}</div>
		</div>
		<div class="center newsBox">
			<div class="flex" style="width: 1300px">
				<div class="left">
					<div class="item" :class="{ itemSeletct: Selectitem == index }" @click="Selectiteming(index, item)" v-for="(item, index) in cateList" :key="index">
						<div>{{ item.title }}</div>
						<i class="el-icon-arrow-right"></i>
					</div>
				</div>

				<router-view :content="content"></router-view>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		async created() {
			const {
				data
			} = await this.$api({
				c: "acticle",
				a: "index",
			});
			this.cateList = data.cate;
			this.content = data.arr;
			this.cate_title = data.banner.title;
			this.cate_content = data.banner.content;
			this.backgrond_img = data.banner.image;
		},
		data() {
			return {
				Selectitem: 0,
				cateList: [],
				content: [],
				backgrond_img: require("@/assets/news/news.png"),
				cate_title: '业务指引',
				cate_content: '汇聚国内优质文化IP版权提供IP交易与授权',
			};
		},
		methods: {
			async Selectiteming(e, item) {
				this.Selectitem = e;
				this.$router.replace("/index/news/list");
				const {
					data
				} = await this.$api({
					c: "acticle",
					a: "yewu_index",
					cate_id: item.id,
				});
				this.content = data.arr;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.news-top {
		height: 400px;
		line-height: 60px;
		// background: url("../../assets/news/news.png");
		background-size: 100% 100%;
		color: #ffffff;
	}

	.newsBox {
		padding: 30px 0px;

		background: rgb(248, 248, 248);

		.item {
			width: 230px;
			padding: 12px 24px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #ffffff;
			transition: all 0.6s;

			&:hover {
				border-bottom: 1px solid #dfdfdf;
			}
		}

		.left {
			background: #ffffff;
			padding-top: 10px;
			margin-right: 30px;
			max-height: 562px;

			.itemSeletct {
				position: relative;
				border-bottom: 1px solid #dfdfdf;

				&::after {
					content: "";
					position: absolute;
					left: 10px;
					top: 50%;
					width: 4px;
					height: 20px;
					background: rgb(0, 118, 254);
					transform: translateY(-50%);
				}
			}
		}
	}
</style>
